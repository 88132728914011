// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { requireAuth } from "./middleware";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // ... остальные маршруты ...
  // {
  //   path: "/crm",
  //   name: "CrmView",
  //   component: () => import("../views/CrmView.vue"),
  //   beforeEnter: requireAuth,
  // },
  {
    path: "/user:id",
    name: "UserView",
    component: () => import("../views/UserView.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/success",
    name: "SuccessPayment",
    component: () => import("../views/SuccessPayment.vue"),
  },
  {
    path: "/privacy",
    name: "PrivacyView",
    component: () => import("../views/PrivacyView.vue"),
  },
  {
    path: "/contacts",
    name: "ContactsView",
    component: () => import("../views/ContactsView.vue"),
  },
  {
    path: "/oferta",
    name: "OfertaView",
    component: () => import("../views/OfertaView.vue"),
  },
  {
    path: "/filter",
    name: "FilterView",
    component: () => import("../views/FilterView.vue"),
    // beforeEnter: requireAuth,
  },
  // {
  //   path: "/public:id",
  //   name: "PublicView",
  //   component: () => import("../views/PublicView.vue"),
  //   // beforeEnter: requireAuth,
  // },

  // {
  //   path: "/marketplatforma/project/:id",
  //   name: "MarketPlatformaProjectView",
  //   component: () => import("../views/MarketPlatformaProjectView.vue"),
  //   beforeEnter: requireAuth,
  // },
  // {
  //   path: "/marketplatforma/report/:id",
  //   name: "MarketPlatformaProjectReportView",
  //   component: () => import("../views/MarketPlatformaProjectReportView.vue"),
  //   beforeEnter: requireAuth,
  // },
  // {
  //   path: "/marketplatforma/posts/:id",
  //   name: "MarketPlatformaProjectPostsView",
  //   component: () => import("../views/MarketPlatformaProjectPostsView.vue"),
  //   beforeEnter: requireAuth,
  // },
  // {
  //   path: "/marketplatforma/groups/:id",
  //   name: "MarketPlatformaProjectGroupsView",
  //   component: () => import("../views/MarketPlatformaProjectGroupsView.vue"),
  //   beforeEnter: requireAuth,
  // },
  // {
  //   path: "/mp-groups/",
  //   name: "MarketPlatformaGroupsStataView",
  //   component: () => import("../views/MarketPlatformaGroupsStataView.vue"),
  //   beforeEnter: requireAuth,
  // },

  // {
  //   path: "/mp-permission/",
  //   name: "MarketPlatformaPermissionView",
  //   component: () => import("../views/MarketPlatformaPermissionView.vue"),
  //   beforeEnter: requireAuth,
  // },

  // ... остальные маршруты ...
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
