<template>
  <header class="app-header">
    <div class="app-header__inner">
      <div class="app-logo">
        <img width="50" height="50" alt="" src="../assets/img/logo.svg" />
        <div class="app-logo__text">
          <!-- <p>Posevun.com</p> -->
          <p>TurboMP</p>
          <p>Турборежим для Маркет-платформы и AdBlogger</p>
        </div>
      </div>

      <div class="app-header__user-info">
        <nav class="app-nav" v-if="currentUser">
          <ul>
            <li><router-link to="/filter">Подбор сообществ</router-link></li>
            <li>
              <router-link :to="'/user' + currentUser.id"
                >Данные и доступы</router-link
              >
            </li>
            <!-- <li><router-link to="/crm">Ручные посевы</router-link></li> -->
          </ul>
        </nav>
        <login-button />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import LoginButton from "@/components/LoginButton.vue";

export default {
  name: "AppHeader",
  components: {
    LoginButton,
  },
  data() {
    return {
      // userId: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  width: 100%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  &__inner {
    width: 100%;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__user-info {
    display: flex;
    align-items: center;
  }
}

.app-logo {
  display: flex;
  align-items: center;
  img {
    margin-right: 15px;
  }
  &__text {
    p {
      margin: 2px 0;
      text-align: left;
      &:first-child {
        font-size: 16px;
        font-weight: bold;
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
}

.app-nav {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    li {
      margin: 0 15px 0 0;
      a {
        text-decoration: none;
        display: block;
        border: 1px solid #eee;
        color: #2d2d2d;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        border-radius: 30px;
        transition: 0.3s;
        &:hover {
          transition: 0.3s;
          background: #027dee;
          color: #fff;
        }
      }
    }
  }
}
</style>
